<template>
    <div class="text-center">
        <!-- Success Icon -->
        <i class="bi bi-check-circle text-success" style="font-size: 72px"></i>

        <!-- Success Message -->
        <h4 class="fw-bold fs-3">
            {{ $t('registration') }} {{ $t('success') }}
        </h4>

        <!-- New Message -->
        <p class="text-secondary">
            {{ $t('form.register.successMsg') }} {{ email }}.<br />
            {{ $t('form.register.returnToLogin') }}
        </p>

        <!-- "Return to Login" Button -->
        <router-link to="/login" class="btn btn-primary mt-3">
            {{ $t('login') }}
        </router-link>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'RegisterPageStepTwo',
    props: {
        email: {
            type: String,
            required: true
        }
    }
});
</script>
